<template>
  <div class="javascriptmas project-component">
    <div class="half-section">
      <p class="paragraph">
        December 2020, I set myself up to a new challenge: creating an advent
        calendar of videos solving small problems in JavaScript, explaining my
        solutions and posting them on Instagram. 👩‍💻
      </p>
      <br />
      <p>
        My goal: encouraging anyone to get into programming. The challenge:
        finding the right words to explain each function, showing my mistakes
        and most importantly getting across that spending time correcting them
        is by far the fun part of coding.
      </p>
      <br />
      <p>
        The outcome: I receive messages from baby developers ready to start
        their coding journey every single day 🦾
      </p>
    </div>
    <div class="half-section half-section-media">
      <img src="@/assets/projects/javascriptmas/album/1.png" alt="" />
    </div>
    <div class="full-section half-section-media">
      <iframe
        width="100%"
        height="500"
        src="https://www.youtube.com/embed/Zv4VBzj4WjE?controls=0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div class="lesson-box">
      <h2>What did I learn ?</h2>
      <br />
      <p>
        This project mostly taught me to be consistent. It took me 1 to 2 hours
        a day to film and edit each video, and I was really proud to not miss
        one single day ! 🥰
      </p>
      <br />
      <p>
        Furthermore, even though the exercices weren't complicated to solve in
        any way, it was a whole other story to explain the code in a simple way
        that would be <strong> understandable for non developers</strong>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Javascriptmas",
};
</script>
<style scoped></style>
